import { observer } from "mobx-react-lite";
import { Footer, Header } from "../layout";

export const CompletePage = observer(() => {
  return (
    <div className="complete">
      <Header />
      <div className="inner">
        <div className="content">
          <div className="location">
            <p>온라인 동의 완료</p>
            <ul className="mon step">
              <li>01</li>
              <li>02</li>
              <li className="on">03</li>
            </ul>
          </div>
          <p className="color-grey">
            검사 동의 완료 되었습니다.
            <br />
            아래 안내 동영상을 참고하여 타액 채취를 해주세요.
          </p>
          <div className="content-wrap">
            <div className="video">
              <div className="video-container">
                <iframe
                  title="NGenBio Corporate Video"
                  key="complete-video"
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/oNb_BkmHinc"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
            <p className="strong sTitle">이렇게 준비하세요!</p>
            <ul>
              <li>
                채취 30분전 음식물 섭취 및 양치, 음료, 껌, 담배를 삼가해 주세요.
              </li>
              <li>
                양 손 주먹을 가볍게 쥐고, 양 볼을 20회 정도 마사지해 주세요.
              </li>
              <li>
                혀 끝을 이용하여 볼 안쪽을 부드럽게 자극한 후, 타액(침)을
                뱉어주세요.
              </li>
              <li>
              충분한 타액(침)이 확보되지 않을 경우 분석결과 제공에 제한이 있을
              수 있으니 주의사항을 반드시 지켜주세요.
              </li>
            </ul>
            <div className="grey-box">
              <p>
                타액 수집 용기를 반송용 봉투에 넣어
                <br /> 스티커로 밀봉해 주세요.
              </p>
            </div>
            <p className="strong sTitle">(반송) 택배 이용방법</p>
            <p>
              유전자검사 키트를 받은 날로부터{" "}
              <span className="strong">3일 뒤(반송) 택배기사님이 방문</span>
              합니다. 반송용 택배기사님에게 전달할 경우 보내실 주소를 기입하지
              않아도 됩니다.
            </p>
            <ul>
              <li>
              부득이하게 개별 택배로 보내실 경우 편하신 택배사를 이용하여 아래
              주소로 보내주세요.
              </li>
            </ul>
            <p>
              키트 배송 문의 <span className="mon strong">1522.4422</span>
            </p>
            <table className="table01">
              <caption>인체유래물 기증자</caption>
              <colgroup>
                <col style={{ width: "40%" }} />
                <col style={{ width: "60%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>보내는 분</th>
                  <td>고객님 정보 입력</td>
                </tr>
                <tr>
                  <th>받는 분</th>
                  <td>
                    <span className="strong">이름</span> (주)엔젠바이오
                    <br />
                    <span className="strong">전화번호</span> 070-4435-8118
                    <br />
                    <span className="strong">우편번호</span> 08390
                    <br />
                    <span className="strong">주소</span>
                    <br />
                    서울특별시 구로구 디지털로 288, 대륭포스트타워 1차 410호
                    세라체크DNA 담당자 앞
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
});
