import { observer } from "mobx-react-lite";
import { Layout } from "../layout";
import {
  EmailDomain,
  emailDomains,
  MeridiemIndicator,
  Page,
  products,
  races,
  Sex,
  SmsVerification,
  state,
} from "../state";
import DaumPostcode from "react-daum-postcode";
import { useState } from "react";
import moment from "moment";
import { filter } from "lodash";

export const PersonalInformationPage = observer(() => {
  const [showDaumPostcode, setShowDaumPostcode] = useState(false);

  return (
    <div className="form">
      <Layout title={"고객정보 입력"} step={1}>
        <form>
          <div className="form01">
            <dl>
              <dt>
                성명<span className="red">(필수)</span>
              </dt>
              <dd>
                <input
                  type="text"
                  className="wFull"
                  maxLength={80}
                  placeholder="성명를 입력하세요."
                  value={state.personalInformation.name}
                  onChange={(e) =>
                    state.personalInformation.setName(e.target.value)
                  }
                />
                <Errors errors={state.personalInformation.nameErrors} />
              </dd>
            </dl>
            <dl>
              <dt>
                휴대폰 본인 인증 <span className="red">(필수)</span>
              </dt>
              <dd
                style={{
                  display:
                    state.personalInformation.smsVerification !==
                    SmsVerification.VERIFIED
                      ? "block"
                      : "none",
                }}
              >
                <input
                  type="text"
                  className="w68"
                  placeholder="휴대폰번호를 “-” 제외하고 숫자만 입력하세요."
                  value={state.personalInformation.mobilePhoneNumber}
                  onChange={(e) => {
                    state.personalInformation.setMobilePhoneNumber(
                      e.target.value
                    );
                  }}
                />
                <a
                  href="#"
                  className="gray"
                  onClick={(e) => {
                    e.preventDefault();
                    state.personalInformation.tryCreateVerification();
                  }}
                >
                  {(() => {
                    switch (state.personalInformation.smsVerification) {
                      case SmsVerification.INIT: {
                        if (
                          state.personalInformation.smsVerificationRequested
                        ) {
                          return "다시발송";
                        }
                        return "SMS 발송";
                      }
                      case SmsVerification.REQUESTING: {
                        return "발송중 ... ";
                      }
                      case SmsVerification.WAIT: {
                        return moment(
                          state.personalInformation.smsVerificationCountdown
                        ).format("mm:ss");
                      }
                      case SmsVerification.WAIT_RETRYABLE: {
                        return (
                          "댜시 발송 " +
                          moment(
                            state.personalInformation.smsVerificationCountdown
                          ).format("mm:ss")
                        );
                      }
                      case SmsVerification.VERIFIED: {
                        return "완료";
                      }
                    }
                  })()}
                </a>
                <br />
                <Errors
                  errors={state.personalInformation.mobilePhoneNumberErrors}
                />
                {state.personalInformation.smsVerificationRequested ? (
                  <>
                    <input
                      type="text"
                      className="w68"
                      placeholder="SMS로 발송된 인증번호를 입력하세요"
                      autoComplete="one-time-code"
                      readOnly={(() => {
                        switch (state.personalInformation.smsVerification) {
                          case SmsVerification.WAIT:
                          case SmsVerification.WAIT_RETRYABLE:
                            return state.personalInformation
                              .smsVerificationVerifying;
                          default:
                            return true;
                        }
                      })()}
                      value={state.personalInformation.smsVerificationSecret}
                      onChange={(e) =>
                        state.personalInformation.setSmsVerificationSecret(
                          e.target.value
                        )
                      }
                    />
                    <a
                      href="#"
                      className="gray"
                      onClick={(e) => {
                        e.preventDefault();
                        state.personalInformation.tryVerifyVerification();
                      }}
                    >
                      {state.personalInformation.smsVerificationVerifying
                        ? "인증중 ..."
                        : "인증하기"}
                    </a>
                  </>
                ) : null}
                <Errors
                  errors={state.personalInformation.smsVerificationErrors}
                />
              </dd>
              <dd
                style={{
                  display:
                    state.personalInformation.smsVerification ===
                    SmsVerification.VERIFIED
                      ? "block"
                      : "none",
                }}
              >
                {/*  TODO :: 휴대전화 번호 하이픈(??) 추가 */}
                <input
                  type="text"
                  value={state.personalInformation.mobilePhoneNumber}
                  className="w70"
                  readOnly
                />
                <a
                  href="#"
                  className="gray2"
                  onClick={(e) => {
                    e.preventDefault();
                    if (state.personalInformation.allowResetSmsVerification) {
                      state.personalInformation.resetSmsVerification();
                    }
                  }}
                >
                  인증완료
                </a>
              </dd>
            </dl>
            <dl>
              <dt>
                이메일 <span className="red">(필수)</span>
              </dt>
              <dd className="email">
                <input
                  type="text"
                  className="input w48 fl"
                  value={state.personalInformation.emailId}
                  onChange={(e) =>
                    state.personalInformation.setEmailId(e.target.value)
                  }
                />
                <div className="w4 fl input-text-center">@</div>
                <input
                  type="text"
                  className="w48 fl"
                  value={state.personalInformation.emailCustomDomain}
                  onChange={(e) =>
                    state.personalInformation.setEmailCustomDomain(
                      e.target.value
                    )
                  }
                  readOnly={
                    state.personalInformation.emailDomain != EmailDomain.CUSTOM
                  }
                />
                <div>
                  <select
                    className="wFull"
                    value={state.personalInformation.emailDomain}
                    onChange={(e) => {
                      state.personalInformation.setEmailDomain(e.target.value);
                      state.personalInformation.setEmailCustomDomain(
                        filter(
                          emailDomains,
                          ([domain, _]) =>
                            domain === state.personalInformation.emailDomain
                        ).map(([_, label]) => label)[0]
                      );
                    }}
                  >
                    {emailDomains.map(([domain, domainUrl]) => (
                      <option value={domain} key={domain}>
                        {domainUrl}
                      </option>
                    ))}
                  </select>
                  <span className="email-domain-select" />
                </div>
                <Errors errors={state.personalInformation.emailErrors} />
              </dd>
            </dl>
            <dl>
              <dt>바코드번호</dt>
              <dd>
                <input
                  type="text"
                  className="input wFull"
                  readOnly
                  value={state.sampleId}
                />
              </dd>
            </dl>
            <dl>
              <dt>
                검체(타액)채취 일시 <span className="red">(필수)</span>
              </dt>
              <dd>
                <ul className="col3">
                  <li className="mb5">
                    <span className="sign" />
                    <select
                      className="wFull"
                      value={state.personalInformation.sampleYear}
                      onChange={(e) =>
                        state.personalInformation.setSampleYear(e.target.value)
                      }
                    >
                      <option value="" hidden>
                        년
                      </option>
                      {state.personalInformation.sampleYears.map((year) => (
                        <option value={year} key={year}>
                          {year}년
                        </option>
                      ))}
                    </select>
                  </li>
                  <li className="mb5">
                    <span className="sign" />
                    <select
                      className="wFull"
                      value={state.personalInformation.sampleMonth}
                      onChange={(e) =>
                        state.personalInformation.setSampleMonth(e.target.value)
                      }
                    >
                      <option value="" hidden>
                        월
                      </option>
                      {state.personalInformation.sampleMonths.map((month) => (
                        <option key={month} value={month}>
                          {month}월
                        </option>
                      ))}
                    </select>
                  </li>
                  <li className="mb5">
                    <span className="sign" />
                    <select
                      className="wFull"
                      value={state.personalInformation.sampleDay}
                      onChange={(e) =>
                        state.personalInformation.setSampleDay(e.target.value)
                      }
                    >
                      <option value="" hidden>
                        일
                      </option>
                      {state.personalInformation.sampleDays.map((day) => (
                        <option key={day} value={day}>
                          {day}일
                        </option>
                      ))}
                    </select>
                  </li>
                  <li>
                    <span className="sign" />
                    <select
                      className="wFull"
                      value={state.personalInformation.sampleMeridiemIndicator}
                      onChange={(e) =>
                        state.personalInformation.setSampleMeridiemIndicator(
                          e.target.value
                        )
                      }
                    >
                      <option value={MeridiemIndicator.AnteMeridiem}>
                        오전
                      </option>
                      <option value={MeridiemIndicator.PostMeridiem}>
                        오후
                      </option>
                    </select>
                  </li>
                  <li>
                    <span className="sign" />
                    <select
                      className="wFull"
                      value={state.personalInformation.sampleHour}
                      onChange={(e) =>
                        state.personalInformation.setSampleHour(e.target.value)
                      }
                    >
                      <option value="" hidden>
                        시
                      </option>
                      {state.personalInformation.sampleHours.map((x) => (
                        <option key={x} value={x}>
                          {x}시
                        </option>
                      ))}
                    </select>
                  </li>
                  <li>
                    <span className="sign" />
                    <select
                      className="wFull"
                      value={state.personalInformation.sampleMinute}
                      onChange={(e) =>
                        state.personalInformation.setSampleMinute(
                          e.target.value
                        )
                      }
                    >
                      <option value="" hidden>
                        분
                      </option>
                      {state.personalInformation.sampleMinutes.map((x) => (
                        <option key={x} value={x}>
                          {x}분
                        </option>
                      ))}
                    </select>
                  </li>
                </ul>
                <Errors errors={state.personalInformation.sampleDateErrors} />
              </dd>
            </dl>
            <dl>
              <dt>
                주소 <span className="red">(필수)</span>
              </dt>
              <dd>
                <input
                  type="text"
                  value={state.personalInformation.address?.zonecode ?? ""}
                  className="w68"
                  readOnly
                  placeholder="우편번호"
                />
                <a
                  href="#"
                  className="gray"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDaumPostcode(true);
                  }}
                >
                  우편번호 찾기
                </a>
                <br />
                <Errors errors={state.personalInformation.addressErrors} />
                <input
                  type="text"
                  value={state.personalInformation.address?.address ?? ""}
                  className="wFull"
                  readOnly
                  placeholder="주소"
                />
                <br />
                <div
                  style={{
                    display: showDaumPostcode ? "block" : "none",
                    marginBottom: "5px",
                    border: "1px solid #f2f2f3",
                  }}
                >
                  <DaumPostcode
                    height={395}
                    onComplete={(data) => {
                      setShowDaumPostcode(false);
                      state.personalInformation.setAddress(data);
                    }}
                  />
                </div>
                <input
                  type="text"
                  className="wFull"
                  maxLength={80}
                  placeholder="상세주소를 입력하세요."
                  value={state.personalInformation.addressDetail}
                  onChange={(e) =>
                    state.personalInformation.setAddressDetail(e.target.value)
                  }
                />
                <Errors
                  errors={state.personalInformation.addressDetailErrors}
                />
              </dd>
            </dl>
            <dl>
              <dt>
                생년월일 <span className="red">(필수)</span>
              </dt>
              <dd>
                <ul className="col3">
                  <li className="mb5">
                    <span className="sign" />
                    <select
                      className="wFull"
                      value={state.personalInformation.birthYear}
                      onChange={(e) =>
                        state.personalInformation.setBirthYear(e.target.value)
                      }
                    >
                      <option value="" hidden>
                        년
                      </option>
                      {state.personalInformation.birthYears.map((year) => (
                        <option value={year} key={year}>
                          {year}년
                        </option>
                      ))}
                    </select>
                  </li>
                  <li className="mb5">
                    <span className="sign" />
                    <select
                      className="wFull"
                      value={state.personalInformation.birthMonth}
                      onChange={(e) =>
                        state.personalInformation.setBirthMonth(e.target.value)
                      }
                    >
                      <option value="" hidden>
                        월
                      </option>
                      {state.personalInformation.birthMonths.map((month) => (
                        <option key={month} value={month}>
                          {month}월
                        </option>
                      ))}
                    </select>
                  </li>
                  <li className="mb5">
                    <span className="sign" />
                    <select
                      className="wFull"
                      value={state.personalInformation.birthDay}
                      onChange={(e) =>
                        state.personalInformation.setBirthDay(e.target.value)
                      }
                    >
                      <option value="" hidden>
                        일
                      </option>
                      {state.personalInformation.birthDays.map((day) => (
                        <option key={day} value={day}>
                          {day}일
                        </option>
                      ))}
                    </select>
                  </li>
                </ul>
                <Errors errors={state.personalInformation.birthdayErrors} />
              </dd>
            </dl>
            <dl>
              <dt>
                성별 <span className="red">(필수)</span>
              </dt>
              <dd>
                <div className="radio_style1">
                  <input
                    type="radio"
                    id="male"
                    value={Sex.Male}
                    checked={state.personalInformation.sex === Sex.Male}
                    onChange={(e) =>
                      state.personalInformation.setSex(e.target.value)
                    }
                  />
                  <label htmlFor="male">
                    <p>
                      <span className="icon" />
                      남자
                    </p>
                  </label>
                  <input
                    type="radio"
                    id="female"
                    value={Sex.Female}
                    checked={state.personalInformation.sex === Sex.Female}
                    onChange={(e) =>
                      state.personalInformation.setSex(e.target.value)
                    }
                  />
                  <label htmlFor="female">
                    <p>
                      <span className="icon" />
                      여자
                    </p>
                  </label>
                </div>
                <Errors errors={state.personalInformation.sexErrors} />
              </dd>
            </dl>
            <dl>
              <dt>
                인종 <span className="red">(필수)</span>
              </dt>
              <dd>
                <span className="sign" />
                <select
                  className="wFull"
                  value={state.personalInformation.race}
                  onChange={(e) =>
                    state.personalInformation.setRace(e.target.value)
                  }
                >
                  {races.map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </dd>
            </dl>
            <dl>
              <dt>
                검사의뢰상품 <span className="red">(필수)</span>
              </dt>
              <dd>
                <span className="sign" />
                <select
                  className="wFull"
                  value={state.personalInformation.product}
                  onChange={(e) =>
                    state.personalInformation.setProduct(e.target.value)
                  }
                >
                  {products.map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </dd>
            </dl>
            <dl>
              <dt>계약번호/주문번호</dt>
              <dd>
                <input
                  type="text"
                  placeholder="계약 번호를 입력하세요."
                  className="input wFull"
                  value={state.personalInformation.contractNumber}
                  onChange={(e) =>
                    state.personalInformation.setContractNumber(e.target.value)
                  }
                />
                <Errors
                  errors={state.personalInformation.contractNumberErrors}
                />
              </dd>
            </dl>
            <dl>
              <dt>상담자</dt>
              <dd>
                <input
                  type="text"
                  placeholder="상담자를 입력하세요."
                  className="input wFull"
                  value={state.personalInformation.counselor}
                  onChange={(e) =>
                    state.personalInformation.setCounselor(e.target.value)
                  }
                />
              </dd>
            </dl>
          </div>
        </form>
        <a
          href="#"
          className="red-btn"
          onClick={(e) => {
            e.preventDefault();
            const error = state.personalInformation.validate();
            if (error) {
              alert(error);
              return;
            }

            state.go(Page.PersonalInformationConfirm);
          }}
        >
          계속하기
        </a>
      </Layout>
    </div>
  );
});

const Errors = (props: { errors: string[] }) => {
  return (
    <>
      {props.errors.map((e) => (
        <p key={e} className="red">
          {e}
        </p>
      ))}
    </>
  );
};
