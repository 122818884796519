import { observer } from "mobx-react-lite";
import { Layout } from "../layout";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { AgreementItem, Page, races, Sex, state } from "../state";
import SignatureCanvas from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";
import classNames from "classnames";
import { filter } from "lodash";

export const AgreementPage = observer(() => {
  let c = 0;
  const signatureCanvasRef = useRef<ReactSignatureCanvas>(null);
  const [signatureDone, setSignatureDone] = useState(false);
  useEffect(() => {
    const onResize = () => {
      // 화면 크기가 변경되면(화면 회전 등) , signatureCanvas 가 클리어 되니 그에 맞추어서 서명 상태도 업데이트 한다.
      setSignatureDone(false);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div className="check">
      <Layout title={"검사 동의 및 서명"} step={2}>
        <form>
          <div className="form02">
            <div className="checkbox style1">
              <input
                type="checkbox"
                id="allchk"
                name="allchk"
                checked={state.agreement.isAllAgreed()}
                onChange={(e) => state.agreement.setAllAgreed(e.target.checked)}
              />
              <label htmlFor="allchk">
                <p>
                  <span className="icon" />
                  모두 동의합니다.
                </p>
              </label>
            </div>
            <Panel item={state.agreement.request}>
              <p className="strong">검사대상자</p>
              <table className="table01">
                <caption>검사대상자</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>성명</th>
                    <td>{state.personalInformation.name}</td>
                  </tr>
                  <tr>
                    <th>생년월일</th>
                    <td>
                      {state.personalInformation.birthday.format("YYYY.MM.DD")}
                    </td>
                  </tr>
                  <tr>
                    <th>검체 채취 일시</th>
                    <td>
                      {state.personalInformation.sampleDate.format(
                        "YYYY.MM.DD / A hh:mm"
                      )}
                      {/*2021.05.04 / AM 09:24*/}
                    </td>
                  </tr>
                  <tr>
                    <th>검체 종류</th>
                    <td>타액</td>
                  </tr>
                  <tr>
                    <th>인종</th>
                    <td>
                      {
                        filter(
                          races,
                          ([race, _]) => race === state.personalInformation.race
                        ).map(([_, label]) => label)[0]
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>성별</th>
                    <td>
                      {(() => {
                        switch (state.personalInformation.sex) {
                          case Sex.Male:
                            return "남자";
                          case Sex.Female:
                            return "여자";
                        }
                      })()}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="strong">검사 의뢰 상품</p>
              <table className="table01">
                <caption>검사 의뢰 상품</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>상품명</th>
                    <td>세라체크DNA</td>
                  </tr>
                </tbody>
              </table>
              <p className="noti red strong">검사의 유효성</p>
              <p>
                해당 검사의 결과는 질병의 진단과는 무관하며, 진단 및 치료결정을
                위해서는 반드시 의사의 상담이 필요합니다. 해당 검사결과에 따른
                개인 맞춤 가이드라인은 현재의 환경요인(건강상태, 건강검진결과
                등)이 100% 반영된 가이드가 아니므로, 현재 상태와 일치하지 않을
                수 있습니다.
              </p>
              <Bar />
              <Picker c={c++} item={state.agreement.request} />
            </Panel>
            <Panel item={state.agreement.consent}>
              <p className="strong">검사대상자</p>
              <table className="table01">
                <caption>검사대상자</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>성명</th>
                    <td>{state.personalInformation.name}</td>
                  </tr>
                  <tr>
                    <th>생년월일</th>
                    <td>
                      {state.personalInformation.birthday.format("YYYY.MM.DD")}
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td>
                      {state.personalInformation.address?.address ?? ""}
                      <br />
                      {state.personalInformation.addressDetail}
                    </td>
                  </tr>
                  <tr>
                    <th>전화번호</th>
                    <td>{state.personalInformation.mobilePhoneNumber}</td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td>{state.personalInformation.email}</td>
                  </tr>
                  <tr>
                    <th>성별</th>
                    <td>
                      {(() => {
                        switch (state.personalInformation.sex) {
                          case Sex.Male:
                            return "남자";
                          case Sex.Female:
                            return "여자";
                        }
                      })()}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="strong">유전자 검사기관</p>
              <table className="table01">
                <caption>유전자 검사기관</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>기관명</th>
                    <td>(주)엔젠바이오</td>
                  </tr>
                  <tr>
                    <th>전화번호</th>
                    <td>070-4435-8118</td>
                  </tr>
                </tbody>
              </table>
              <p className="strong">유전자 검사항목</p>
              <table className="table01">
                <caption>유전자 검사항목</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>검사목적</th>
                    <td>개인유전자검사</td>
                  </tr>
                  <tr>
                    <th>검사명</th>
                    <td>세라체크DNA</td>
                  </tr>
                </tbody>
              </table>
              <p>
                본인은 “생명윤리 및 안전에 관한 법률” 제51조 및 같은 법 시행규칙
                제51조에 따라 해당 유전자 검사에 대하여 충분한 설명을 들어
                이해하였으며 위와 같이 본인에 대한 유전자 검사에 자발적인 의사로
                동의합니다.
              </p>
              <Picker c={c++} item={state.agreement.consentVoluntarily} />
              <Bar />
              <p>
                동일한 대상 및 목적을 위한 추가적인 유전자검사에 대해서는 별도의
                동의서 작성없이 아래 동의만 추가 할 수 있습니다.
              </p>
              <Picker c={c++} item={state.agreement.consentAdditional} />
              <Bar />
              <p className="strong">[유의사항]</p>
              <ul className="mt10">
                <li className="dot">
                  이 유전자 검사의 결과는 10년간 보존되며, 법 제52조 제2항에
                  따라 본인이나 법정대리인이 요청하는 경우 열람할 수 있습니다.
                </li>
                <li className="dot">
                  검사 후 남은 검사대상물을 인체유래물연구 또는 허가 받은
                  인체유래물은행에 기증하는 것에 동의하는 경우에 는 연구의 목적,
                  개인정보의 제공에 관한 사항 등 제공에 관한 구체적인 설명을
                  충분히 듣고, 별지 제34호의 인체 유래물연구 동의서 또는 별지
                  제41호의 인체유래물 등 의 기증 동의서를 추가로 작성하여야
                  합니다.
                </li>
              </ul>
            </Panel>
            <Panel item={state.agreement.personal}>
              <p>
                유전자검사 서비스(이하 “본 서비스”) 제공을 위하여 아래 와 같이
                개인정보를 수집, 이용 제공하고자합니다. 내용을 자세히 읽으신 후
                동의 여부를 결정하여 주십시오. 수집된 정보는 본 서비스에
                한정하여 이용되며, 의뢰 및 동의한 내용 외 다른 목적으로 사용되지
                않습니다. 제공된 개인 정보는 (주)엔젠바이오 세라체크DNA 담당자
                (TEL. 070-4435-8118)를 통해 열람, 정정, 삭제를 요구할 수
                있습니다.
              </p>
              <table className="table01 mt20">
                <caption>수집 항목</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>수집 항목</th>
                    <td>성명, 연락처, 주소, 나이, 생년월일, 성별, 이메일 등</td>
                  </tr>
                  <tr>
                    <th>수집 목적</th>
                    <td>
                      본인 확인 및 고객관리, 검사 결과지 제공, 본 서비스 제공 등
                    </td>
                  </tr>
                  <tr>
                    <th>이용 및 보유기간</th>
                    <td>수집일로부터 10년간 혹은 철회 시까지</td>
                  </tr>
                </tbody>
              </table>
              <p>
                위의 개인정보 수집 이용에 대한 동의를 거부할 권리가 있습니다.
                그러나 동의하지 않을 경우 본 서비스를 제공받 을 수 없습니다.
                <br />
                <br />
                위와 같이 개인정보를 수집, 이용하는데 동의하십니까?
              </p>
              <Bar />
              <Picker c={c++} item={state.agreement.personal} />
            </Panel>
            <Panel item={state.agreement.sensitive}>
              <table className="table01">
                <caption>민감정보 수집 및 이용에 대한 동의</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>수집 항목</th>
                    <td>유전정보, 건강정보</td>
                  </tr>
                  <tr>
                    <th>수집 목적</th>
                    <td>본 서비스 제공 등</td>
                  </tr>
                  <tr>
                    <th>이용 및 보유기간</th>
                    <td>수집일로부터 10년간 혹은 철회 시까지</td>
                  </tr>
                </tbody>
              </table>
              <p>
                위의 민감정보 수집 이용에 대한 동의를 거부할 권리가 있습니다.
                그러나 동의하지 않을 경우 본 서비스를 제공 받을 수 없습니다.
                <br />
                <br />
                위와 같이 민감정보를 수집, 이용하는데 동의하십니까?
              </p>
              <Bar />
              <Picker c={c++} item={state.agreement.sensitive} />
            </Panel>
            <Panel item={state.agreement.personalThirdParties}>
              <table className="table01">
                <caption>개인정보 제3자 제공에 대한 동의</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>제공받는 자</th>
                    <td>(주)세라젬, (주)세라젬 HC 상담사, (주)온앤원</td>
                  </tr>
                  <tr>
                    <th>제공 항목</th>
                    <td>유전자검사정보, 건강정보</td>
                  </tr>
                  <tr>
                    <th>제공 목적</th>
                    <td>맞춤형 건강관리, 서비스 제공 등</td>
                  </tr>
                  <tr>
                    <th>이용 및 보유기간</th>
                    <td>수집일로부터 10년간 혹은 철회 시까지</td>
                  </tr>
                </tbody>
              </table>
              <p>
                위의 개인정보 제3자 제공에 대한 동의를 거부할 권리가 있습니다.
                그러나 동의하지 않을 경우 맞춤형 건강관리 서비스를 제공받을 수
                없습니다.
                <br />
                <br />
                위와 같이 개인정보를 제3자에게 제공하는데 동의하십니까?
              </p>
              <Bar />
              <Picker c={c++} item={state.agreement.personalThirdParties} />
            </Panel>
            <Panel item={state.agreement.sensitiveThirdParties}>
              <table className="table01">
                <caption>민감정보 제3자 제공에 대한 동의</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>제공받는 자</th>
                    <td>(주)세라젬, (주)세라젬 HC 상담사, (주)온앤원</td>
                  </tr>
                  <tr>
                    <th>제공 항목</th>
                    <td>유전자검사정보, 건강정보, 혈통/민족</td>
                  </tr>
                  <tr>
                    <th>제공 목적</th>
                    <td>맞춤형 건강관리, 서비스 제공 등</td>
                  </tr>
                  <tr>
                    <th>이용 및 보유기간</th>
                    <td>수집일로부터 10년간 혹은 철회 시까지</td>
                  </tr>
                </tbody>
              </table>
              <p>
                위의 민감정보 제3자 제공에 대한 동의를 거부할 권리가 있습니다.
                그러나 동의하지 않을 경우 맞춤형 건강관리 서비스를 제공받을 수
                없습니다.
                <br />
                <br />
                위와 같이 민감정보를 제3자에게 제공하는데 동의하십니까?
              </p>
              <Bar />
              <Picker c={c++} item={state.agreement.sensitiveThirdParties} />
            </Panel>
            <Panel item={state.agreement.entrustment}>
              <table className="table01">
                <caption>개인정보의 위탁에 관한 동의</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>항목</th>
                    <td>성명, 주소, 연락처 등</td>
                  </tr>
                  <tr>
                    <th>위탁 대상자</th>
                    <td>
                      ㈜세라젬, ㈜세라젬C&S, HC상담사, 나비엘 및 CB5
                      가맹점/대리점, (주)온앤원, CJ대한통운, 우체국택배,
                      로젠택배, 한진택배, 현대택배
                    </td>
                  </tr>
                  <tr>
                    <th>위탁 목적</th>
                    <td>검사 결과지 배송</td>
                  </tr>
                  <tr>
                    <th>이용 및 보유기간</th>
                    <td>결과지 배송 완료 후 파기</td>
                  </tr>
                </tbody>
              </table>
              <p>
                위의 개인정보 위탁에 관한 동의를 거부할 권리가 있습니다. 그러나
                동의하지 않을 경우 결과지(인쇄물) 배송이 불가합니다.
                <br />
                <br />
                위와 같이 개인정보를 위탁에 동의하십니까?
              </p>
              <Bar />
              <Picker c={c++} item={state.agreement.entrustment} />
            </Panel>
            <Panel item={state.agreement.humanMaterials}>
              <p className="strong">인체유래물 기증자</p>
              <table className="table01">
                <caption>인체유래물 기증자</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>성명</th>
                    <td>{state.personalInformation.name}</td>
                  </tr>
                  <tr>
                    <th>생년월일</th>
                    <td>
                      {state.personalInformation.birthday.format("YYYY.MM.DD")}
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td>
                      {state.personalInformation.address?.address ?? ""}
                      <br />
                      {state.personalInformation.addressDetail}
                    </td>
                  </tr>
                  <tr>
                    <th>전화번호</th>
                    <td>{state.personalInformation.mobilePhoneNumber}</td>
                  </tr>
                  <tr>
                    <th>성별</th>
                    <td>
                      {(() => {
                        switch (state.personalInformation.sex) {
                          case Sex.Male:
                            return "남자";
                          case Sex.Female:
                            return "여자";
                        }
                      })()}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="strong">연구책임자</p>
              <table className="table01">
                <caption>연구책임자</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>성명</th>
                    <td>양성우</td>
                  </tr>
                  <tr>
                    <th>전화번호</th>
                    <td>02-867-9798</td>
                  </tr>
                </tbody>
              </table>
              <p>
                이 동의서는 귀하로부터 수집된 인체유래물 등(인체유래물과
                그로부터 얻은 유전정보를 말합니다.)을 질병의 진단 및 치료법 개발
                등의 연구에 활용하기 위한 것입니다. 동의는 자발적으로
                이루어지므로 아래의 내용을 읽고 궁금한 사항은 상담자에게 묻고
                질문할 기회를 가지고 충분히 생각한 후 결정하시기 바라며, 이
                동의서에 대한 동의 여부는 귀하의 향후 검사 및 치료 등에 어떤
                영향도 미치지 않습니다.
              </p>
              <ul className="mt10">
                <li className="dot">
                  인체유래물이란 인체로부터 수집하거나 채취한 조직·
                  세포·혈액·체액 등 인체 구성물 또는 이들로부터 분리 된 혈청,
                  혈장, 염색체, DAN, RNA, 단백질 등을 말하며, 귀하의
                  인체유래물을 채취하기 전에 채취방법 및 과정에 관한 설명을
                  충분히 들어야 합니다.
                </li>
                <li className="dot">
                  귀하가 귀하의 인체유래물 등을 아래의 연구목적에 이용하도록
                  동의하는 경우, 귀하의 인체유래물 등의 보존기간, 다른 사람 또는
                  다른 연구목적에 대한 제공 여부, 제공 시 개인정보처리에 관한
                  사항 및 폐기 등을 결정할 수 있습니다. 또한 동의한 사항에 대해
                  언제든지 동의를 철회할 수 있습니다. 이 경우 연구의 특성에 따라
                  철회 전까지 수집된 귀하의 인체유래물 등과 기록 및 정보 등의
                  처리방법이 달라질 수 있으므로 연구자로부터 별도의 설명문 등을
                  통해 정보를 받으실 것입니다.
                </li>
                <li className="dot">
                  귀하는 이 연구 참여와 관련하여 귀하의 동의서 및 귀하의
                  인체유래물 등의 제공 및 폐기 등에 관한 기록을 본인 또는
                  법정대리인을 통하여 언제든지 열람할 수 있습니다.
                </li>
                <li className="dot">
                  귀하가 결정한 보존기간이 지난 인체유래물은 「폐기물 관리법」
                  제13조에 따른 기준 및 방법에 따라 폐기되며, 해당기관의
                  휴업·폐업 등 해당 연구가 비정상적으로 종료될 때에는 법에서
                  정한 절차에 따라 인체유래물 등을 이관할 것입니다.
                </li>
                <li className="dot">
                  귀하의 인체유래물 등을 이용하는 연구는 「생명윤리 및 안전에
                  관한 법률」에 따라 해당 기관의 기관생명윤리위원회의 승인 후
                  진행될 것이며 해당 기관 및 연구자는 귀하의 개인정보 보호를
                  위하여 필요한 조치를 취할 것입니다.
                </li>
                <li className="dot">
                  귀하의 인체유래물 등을 이용한 연구는 학회와 학술지에 연구자의
                  이름으로 발표되고 귀하의 개인정보는 드러나 지 않을 것입니다.
                  <br />
                  <br />
                  <span className="red">
                    위의 모든 사항에 대해 충분한 설명을 듣고, 작성된 동의서
                    사본을 1부 받아야 합니다.
                  </span>
                </li>
              </ul>
              <Bar />
              <p className="strong">동의 내용</p>
              <table className="table01">
                <caption>동의 내용</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>연구 목적</th>
                    <td>
                      DTC 유전자 검사 서비스 고도화[IRB
                      20160804884-210520-BR-001]
                    </td>
                  </tr>
                  <tr>
                    <th>인체유래물 종류 및 수량</th>
                    <td>타액, DNA, 유전정보</td>
                  </tr>
                  <tr>
                    <th>인체유래물 보존기간</th>
                    <td>영구보존</td>
                  </tr>
                  <tr>
                    <th>보존기간 내 2차적 사용을 위한 제공 여부</th>
                    <td>포괄적 연구 목적으로 제공하는 것에 동의</td>
                  </tr>
                  <tr>
                    <th>2차적 사용을 위한 제공 시 개인식별정보 포함 여부</th>
                    <td>개인식별정보 불포함</td>
                  </tr>
                  <tr>
                    <th>유전정보 데이터 보관, 추가 분석 여부</th>
                    <td>수집일로부터 10년 또는 철회 요구시까지</td>
                  </tr>
                </tbody>
              </table>
              <p>
                본인은 「생명윤리 및 안전에 관한 법률」 제37조 및 같은 법
                시행규칙 제34조에 따라 해당 인체유래물연구의 목적 등 연구 참여와
                관련하여 인체유래물 채취 방법 및 과정 등에 대한 동의서의 내용에
                대하여 충분한 설명을 들어 이해하였으므로 위와 같이 본인의
                인체유래물 등을 기증하는 것에 자발적으로 의사를 동의합니다.
              </p>
              <Bar />
              <Picker c={c++} item={state.agreement.humanMaterials} />
            </Panel>
            <Panel item={state.agreement.marketing}>
              <table className="table01">
                <caption>마케팅 정보 수신에 대한 동의</caption>
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>수집 항목</th>
                    <td>성명, 생년월일, 성별, 연락처</td>
                  </tr>
                  <tr>
                    <th>수집 목적</th>
                    <td>서비스 안내 및 개선</td>
                  </tr>
                  <tr>
                    <th>이용 및 보유기간</th>
                    <td>수집일로부터 10년간 혹은철회 시까지</td>
                  </tr>
                </tbody>
              </table>
              <p>
                위의 마케팅 정보 수신에 대한 동의를 거부할 권리가 있습니다.
                동의하지 않는 경우에도 본서비스 이용은 가능합니다.
                <br />
                <br />
                위와 같이 마케팅 정보를 수신하는데 동의하십니까?
              </p>
              <Bar />
              <Picker c={c++} item={state.agreement.marketing} />
            </Panel>
          </div>
        </form>
        <div className="sign">
          <p>{state.personalInformation.name} 님, 서명해 주세요.</p>
          <div>
            <SignatureCanvas
              ref={signatureCanvasRef}
              onEnd={() => setSignatureDone(true)}
              canvasProps={{
                style: { width: "100%", height: "100%", minHeight: "150px" },
              }}
            />
          </div>
          <span
            className="sign-reset"
            onClick={(e) => {
              e.preventDefault();
              setSignatureDone(false);
              signatureCanvasRef.current?.clear();
            }}
          >
            사인 초기화
          </span>
        </div>
        <a
          href="#"
          className="red-btn"
          onClick={(e) => {
            e.preventDefault();
            const error = state.agreement.validate()[0];
            if (error) {
              alert(error);
              return;
            }
            const signatureCanvas = signatureCanvasRef?.current;
            if (!signatureCanvas) {
              alert(
                "서명 시스템을 준비 중입니다.\n잠시 후 다시 시도하여 주십시오."
              );
              return;
            }
            if (!signatureDone) {
              alert("서명을 작성하세요");
              return;
            }
            state.agreement.setSignature(
              signatureCanvas.getTrimmedCanvas().toDataURL("image/png")
            );
            state.go(Page.Survey);
          }}
        >
          확인
        </a>
        <div className="mt20">
          <p className="red center">
            필수항목을 읽고 동의해주셔야 서비스 이용이 가능합니다.
          </p>
          {/*<p className="red center">서명을 작성하세요</p>*/}
        </div>
      </Layout>
    </div>
  );
});

const Panel = observer((props: PropsWithChildren<{ item: AgreementItem }>) => {
  const [opened, setOpened] = useState(false);
  return (
    <dl>
      <dt
        className="checkbox style2"
        onClick={() => {
          setOpened(true);
        }}
      >
        <input type="checkbox" checked={props.item.agree} readOnly />
        <label>
          <p>
            <span className="icon" />
            {props.item.title}{" "}
            <span className={classNames({ red: props.item.required })}>
              ({props.item.required ? "필수" : "선택"})
            </span>
          </p>
        </label>
        <span className="arrow" />
      </dt>
      <dd style={{ display: opened ? "block" : "none" }}>
        <div className="dd_content">{props.children}</div>
        <a
          href="#"
          className="close-dd"
          onClick={(e) => {
            e.preventDefault();
            setOpened(false);
          }}
        >
          <span>닫기</span>
        </a>
      </dd>
    </dl>
  );
});

const Bar = () => <div className="bar" />;

const Picker = observer(
  (props: {
    c: number;
    item: { agree: boolean; setAgree: (agree: boolean) => void };
  }) => {
    return (
      <>
        <div className="radio_style2">
          <input
            type="radio"
            id={`agree${props.c}_ok`}
            value="y"
            checked={props.item.agree}
            onChange={(e) => props.item.setAgree(e.target.checked)}
          />
          <label htmlFor={`agree${props.c}_ok`}>
            <p>
              <span className="icon" />
              동의
            </p>
          </label>
          <input
            type="radio"
            id={`agree${props.c}_no`}
            value="n"
            checked={!props.item.agree}
            onChange={(e) => props.item.setAgree(!e.target.checked)}
          />
          <label htmlFor={`agree${props.c}_no`}>
            <p>
              <span className="icon" />
              미동의
            </p>
          </label>
        </div>
      </>
    );
  }
);
