import { find, flatten, includes, range } from "lodash";
import { makeAutoObservable } from "mobx";
import * as queryString from "querystring";
import { ParsedUrlQuery } from "querystring";
import { numberOnly } from "../utils";
import moment from "moment";
import { AddressData } from "react-daum-postcode";
import { email } from "@hapi/address";

export enum Page {
  Index,
  DtcManual,
  PersonalInformation,
  PersonalInformationConfirm,
  Agreement,
  Survey,
  Complete,
}

const pages: Page[] = [
  Page.Index,
  Page.DtcManual,
  Page.PersonalInformation,
  Page.PersonalInformationConfirm,
  Page.Agreement,
  Page.Survey,
  Page.Complete,
];

export enum Environment {
  Development = "Development",
  Test = "Test",
  Production = "Production",
}

const environments: Environment[] = [
  Environment.Development,
  Environment.Test,
  Environment.Production,
];

export enum MeridiemIndicator {
  AnteMeridiem = "오전",
  PostMeridiem = "오후",
}

export const meridiemIndicators = [
  MeridiemIndicator.AnteMeridiem,
  MeridiemIndicator.PostMeridiem,
];

export enum Sex {
  Male = "Male",
  Female = "Female",
}

export const sexes: Sex[] = [Sex.Male, Sex.Female];

export enum Race {
  Korean = "P001",
  Japanese = "P002",
  Chinese_Taiwanese = "P003",
  Mongolians = "P004",
  Indians_NepalesePeople = "P005",
  Europeans = "P006",
  African = "P007",
  Others = "P008",
}

export const races: [Race, string][] = [
  [Race.Korean, "동아시아인_한국계"],
  [Race.Japanese, "동아시아인_일본계"],
  [Race.Chinese_Taiwanese, "동아시아인_중국/대만계"],
  [Race.Mongolians, "동아시아인_몽골계"],
  [Race.Indians_NepalesePeople, "남아시아인_인도/네팔계"],
  [Race.Europeans, "유럽인"],
  [Race.African, "아프리카인"],
  [Race.Others, "기타 인종"],
];

export enum Product {
  CeraDna = "K001",
}

export const products: [Product, string][] = [[Product.CeraDna, "세라체크DNA"]];

export enum SmsVerification {
  INIT,
  REQUESTING,
  WAIT,
  WAIT_RETRYABLE,
  VERIFIED,
}

export enum EmailDomain {
  CUSTOM,
  NAVER,
  DAUM,
  GMAIL,
}

export const emailDomains: [EmailDomain, string][] = [
  [EmailDomain.CUSTOM, "직접입력"],
  [EmailDomain.NAVER, "naver.com"],
  [EmailDomain.DAUM, "daum.net"],
  [EmailDomain.GMAIL, "gmail.com"],
];

export class PersonalInformation {
  name = "";
  nameErrors: string[] = [];
  mobilePhoneNumber = "";
  mobilePhoneNumberErrors: string[] = [];
  emailId = "";
  emailDomain: EmailDomain = EmailDomain.NAVER;
  emailCustomDomain = "naver.com";
  email = "";
  emailErrors: string[] = [];

  sampleYear = "";
  readonly sampleYears = range(2021, moment().year() + 1).map((x) =>
    x.toString()
  );
  sampleMonth = "";
  readonly sampleMonths = range(1, 13).map((x) => x.toString());
  sampleDay = "";
  sampleDays: string[] = [];
  sampleMeridiemIndicator = MeridiemIndicator.AnteMeridiem;
  sampleHour = "";
  readonly sampleHours = range(1, 13).map((x) => x.toString());
  sampleMinute = "";
  readonly sampleMinutes = range(0, 60).map((x) => x.toString());
  sampleDate = moment();
  sampleDateErrors: string[] = [];
  addressDetail = "";
  addressDetailErrors: string[] = [];

  birthYear = "";
  readonly birthYears = range(1900, moment().year() + 1).map((x) =>
    x.toString()
  );
  birthMonth = "";
  readonly birthMonths = range(1, 13).map((x) => x.toString());
  birthDay = "";
  birthDays: string[] = [];
  birthday = moment("1991.01.01", "YYYY.MM.DD");
  birthdayErrors: string[] = [];

  sex: Sex | undefined = undefined;
  sexErrors: string[] = [];
  race: Race = Race.Korean;
  product: Product = Product.CeraDna;
  contractNumber = "";
  contractNumberErrors: string[] = [];
  counselor = "";
  address: AddressData | undefined;
  addressErrors: string[] = [];

  // 휴대전화 번호 인증
  smsVerificationIntervalId?: NodeJS.Timeout;
  smsVerificationCountdown = 0;
  smsVerificationOverflowed = false;
  smsVerificationRequestId = "";
  smsVerificationSecret = "";
  smsVerificationRequested = false;
  smsVerification: SmsVerification = SmsVerification.INIT;
  smsVerificationVerifying = false;
  smsVerificationMaxWait = 5 * 60 * 1000;
  // noinspection PointlessArithmeticExpressionJS
  smsVerificationMinimumWait = 1 * 60 * 1000;
  smsVerificationErrors: string[] = [];
  allowResetSmsVerification = false;

  constructor() {
    makeAutoObservable(this);
  }

  setMobilePhoneNumber(mobilePhoneNumber: string) {
    this.mobilePhoneNumber = numberOnly(mobilePhoneNumber);
  }

  setSampleYear(sampleYear: string) {
    this.sampleYear = sampleYear;
    this.resetSampleDays();
  }

  setSampleMonth(sampleMonth: string) {
    this.sampleMonth = sampleMonth;
    this.resetSampleDays();
  }

  setSampleDay(sampleDay: string) {
    this.sampleDay = sampleDay;
  }

  setSampleMeridiemIndicator(meridiem: string) {
    this.sampleMeridiemIndicator =
      find(meridiemIndicators, (x) => x === meridiem) ??
      this.sampleMeridiemIndicator;
  }

  setSampleHour(sampleHour: string) {
    this.sampleHour = sampleHour;
  }

  setSampleMinute(sampleMinute: string) {
    this.sampleMinute = sampleMinute;
  }

  setAddressDetail(addressDetail: string) {
    this.addressDetail = addressDetail;
  }

  setBirthYear(birthYear: string) {
    this.birthYear = birthYear;
    this.resetBirthDays();
  }

  setBirthMonth(birthMonth: string) {
    this.birthMonth = birthMonth;
    this.resetBirthDays();
  }

  setBirthDay(birthDay: string) {
    this.birthDay = birthDay;
  }

  setSex(sex: string) {
    this.sex = find(sexes, (x) => x === sex);
  }

  setRace(race: string) {
    this.race =
      find(
        races.map((x) => x[0]),
        (x) => x === race
      ) ?? this.race;
  }

  setProduct(product: string) {
    this.product =
      find(
        products.map((x) => x[0]),
        (x) => x === product
      ) ?? this.product;
  }

  setCounselor(counselor: string) {
    this.counselor = counselor;
  }

  setContractNumber(contractNumber: string) {
    this.contractNumber = contractNumber.trim().replaceAll(/[^cCtT0-9]/gi, "");
  }

  validate(): string | undefined {
    return flatten([
      this.validateName(),
      this.validateMobilePhoneNumber(),
      this.validateEmail(),
      this.validateSmsVerification(),
      this.validateSampleDate(),
      this.validateAddress(),
      this.validateBirthDate(),
      this.validateSex(),
      //this.validateContractNumber(),
    ])[0];
  }

  setAddress(address: AddressData) {
    this.address = address;
  }

  tryVerifyVerification() {
    if (this.smsVerificationVerifying) {
      alert("인증중입니다.");
      return;
    }

    switch (this.smsVerification) {
      case SmsVerification.WAIT:
      case SmsVerification.WAIT_RETRYABLE: {
        this.smsVerificationSecret = this.smsVerificationSecret.trim();
        if (!this.smsVerificationSecret.length) {
          alert("인증번호를 입력하여 주십시오.");
          return;
        }

        this.verifyVerification();
        return;
      }
      default: {
        alert("인증번호 발송을 먼저 요청하여 주십시오.");
        return;
      }
    }
  }

  tryCreateVerification() {
    const errors = this.validateMobilePhoneNumber();
    if (errors.length) {
      alert(errors[0]);
      return;
    }

    this.smsVerificationErrors = [];

    if (this.smsVerificationVerifying) {
      alert("인증중입니다.");
      return;
    }

    switch (this.smsVerification) {
      case SmsVerification.INIT: {
        this.createVerification();
        return;
      }
      case SmsVerification.REQUESTING: {
        alert("인증번호를 요청 중입니다.");
        return;
      }
      case SmsVerification.WAIT: {
        alert(
          moment(this.smsVerificationMinimumWait).format("m") +
            "분후 다시 시도하여 주십시오."
        );
        return;
      }
      case SmsVerification.WAIT_RETRYABLE: {
        // 타이머를 멈추고
        this.setSmsVerificationIntervalId(undefined);
        // 처음부터 새로 시작한다.
        this.setSmsVerification(SmsVerification.INIT);
        this.createVerification();
        return;
      }
      case SmsVerification.VERIFIED: {
        alert("이미 인증 처리 되었습니다.");
        return;
      }
    }
  }

  setSmsVerificationSecret(secret: string) {
    this.smsVerificationSecret = numberOnly(secret);
  }

  resetSmsVerification() {
    this.setSmsVerification(SmsVerification.INIT);
  }

  setName(name: string) {
    this.name = name;
  }

  setEmailId(emailId: string) {
    this.emailId = emailId;
  }

  setEmailDomain(emailDomain: string) {
    emailDomains
      .map((xy) => xy[0])
      .filter((domain) => domain.toString() === emailDomain)
      .forEach((domain) => (this.emailDomain = domain));
  }

  setEmailCustomDomain(emailCustomDomain: string) {
    this.emailCustomDomain = emailCustomDomain;
  }

  private verifyVerification() {
    this.smsVerificationVerifying = true;

    request("/mng/hsu/verification/verify", {
      requestId: this.smsVerificationRequestId,
      secret: this.smsVerificationSecret,
      sampleId: state.sampleId,
    })
      .then((data: { requestId: string } & RequestResponse) => {
        if (alertErrors(data)) {
          return;
        }
        this.setSmsVerificationIntervalId(undefined);
        this.setSmsVerification(SmsVerification.VERIFIED);
        alert("인증되었습니다.");
      })
      .catch(defaultExceptionHandler)
      .finally(() => {
        this.setSmsVerificationVerifying(false);
      });
  }

  private createVerification() {
    this.smsVerification = SmsVerification.REQUESTING;

    // 인증 요청
    request("/mng/hsu/verification", {
      phoneNumber: this.mobilePhoneNumber,
    })
      .then((data: { requestId: string } & RequestResponse) => {
        if (alertErrors(data)) {
          return;
        }

        this.smsVerificationRequested = true;
        // 인증번호
        this.setSmsVerificationRequestId(data.requestId);
        // 대기 상태로 변경
        this.setSmsVerification(SmsVerification.WAIT);
        // 타임아웃 보여주기
        const overflowAt = moment().add(
          this.smsVerificationMaxWait,
          "milliseconds"
        );
        const updateCountdown = () => {
          let countDown = overflowAt.diff(moment());
          this.setSmsVerificationCountdown(countDown);

          if (
            this.smsVerificationMaxWait - countDown >
            this.smsVerificationMinimumWait
          ) {
            this.setSmsVerification(SmsVerification.WAIT_RETRYABLE);
          }

          // 포기
          if (countDown <= 0) {
            this.setSmsVerificationIntervalId(undefined);
            this.smsVerificationRequestId = "";
            this.smsVerification = SmsVerification.INIT;
          }
        };
        this.setSmsVerificationIntervalId(setInterval(updateCountdown, 100));
        updateCountdown();
      })
      .catch(defaultExceptionHandler)
      .finally(() => {
        // 진행 상태가 그대로 이면 아마도 오류가 발생하였을 것이다.
        if (this.smsVerification === SmsVerification.REQUESTING) {
          this.setSmsVerification(SmsVerification.INIT);
        }
      });
  }

  private validateContractNumber(): string[] {
    // 계약번호 검증
    this.contractNumberErrors = [];
    this.contractNumber = this.contractNumber.trim().toUpperCase();
    if (!this.contractNumber) {
      this.contractNumberErrors = ["계약 번호를 입력하세요."];
    } else if (!this.contractNumber.match(/^CT[0-9]{10}$/)) {
      this.contractNumberErrors = ["계약번호가 올바르지 않습니다."];
    }
    return this.contractNumberErrors;
  }

  private validateSex(): string[] {
    // 성별 검증
    this.sexErrors = [];
    if (!this.sex) {
      this.sexErrors = ["성별을 선택하세요"];
    }
    return this.sexErrors;
  }

  private resetSampleDays() {
    if (!this.sampleYear || !this.sampleMonth) {
      return;
    }
    const daysInMonth = moment(
      `${this.sampleYear}-${this.sampleMonth}`,
      "YYYY-MM"
    ).daysInMonth();
    this.sampleDays = range(1, daysInMonth + 1).map((x) => x.toString());
    if (!includes(this.sampleDays, this.sampleDay)) {
      this.sampleDay = "";
    }
  }

  private resetBirthDays() {
    if (!this.birthYear || !this.birthMonth) {
      return;
    }
    const daysInMonth = moment(
      `${this.birthYear}-${this.birthMonth}`,
      "YYYY-MM"
    ).daysInMonth();
    this.birthDays = range(1, daysInMonth + 1).map((x) => x.toString());
    if (!includes(this.birthDays, this.birthDay)) {
      this.birthDay = "";
    }
  }

  private validateAddress(): string[] {
    this.addressDetailErrors = [];
    this.addressErrors = [];

    if (!this.address) {
      this.addressErrors = ["주소를 입력하세요"];
    }
    this.addressDetail = this.addressDetail.trim();
    if (!this.addressDetail) {
      this.addressDetailErrors = ["상세 주소를 입력해주세요"];
    }
    return this.addressErrors.concat(this.addressDetailErrors);
  }

  private validateSampleDate(): string[] {
    // 검체 채취 일시
    this.sampleDateErrors = [];
    const missingDateComponent =
      find(
        [
          this.sampleYear,
          this.sampleMonth,
          this.sampleDay,
          this.sampleHour,
          this.sampleMinute,
        ],
        (x) => x === ""
      ) === "";
    if (missingDateComponent) {
      this.sampleDateErrors = ["검체(타액) 채취 일시를 입력해주세요"];
      return this.sampleDateErrors;
    }

    this.sampleDate = moment(
      `${this.sampleYear}-${this.sampleMonth}-${this.sampleDay} ${this.sampleHour}:${this.sampleMinute} ${this.sampleMeridiemIndicator}`,
      "YYYY-M-D h:m A",
      true
    );
    if (!this.sampleDate.isValid()) {
      this.sampleDateErrors = ["검체(타액) 채취 일시를 올바르게 입력해주세요"];
      return this.sampleDateErrors;
    }

    if (this.sampleDate.isSameOrAfter(moment())) {
      this.sampleDateErrors = [
        "검체(타액) 채취 일시는 미래의 시간을 입력할 수 없습니다.",
      ];
      return this.sampleDateErrors;
    }

    return this.sampleDateErrors;
  }

  private validateBirthDate(): string[] {
    // 검체 채취 일시
    this.birthdayErrors = [];
    const missingDateComponent =
      find(
        [this.birthYear, this.birthMonth, this.birthDay],
        (x) => x === ""
      ) === "";
    if (missingDateComponent) {
      this.birthdayErrors = ["생년월일을 입력해주세요"];
      return this.birthdayErrors;
    }

    this.birthday = moment(
      `${this.birthYear}-${this.birthMonth}-${this.birthDay}`,
      "YYYY-M-D",
      true
    );
    if (!this.birthday.isValid()) {
      this.birthdayErrors = ["생년월일을 올바르게 입력해주세요"];
      return this.birthdayErrors;
    }

    if (this.birthday.isSameOrAfter(moment())) {
      this.birthdayErrors = ["생년월일을은 미래의 시간을 입력할 수 없습니다."];
      return this.birthdayErrors;
    }

    return this.birthdayErrors;
  }

  private validateMobilePhoneNumber(): string[] {
    this.mobilePhoneNumberErrors = [];
    this.mobilePhoneNumber = this.mobilePhoneNumber.trim();
    if (!this.mobilePhoneNumber) {
      this.mobilePhoneNumberErrors = ["휴대폰번호를 입력해주세요."];
      return this.mobilePhoneNumberErrors;
    }
    if (/[^0-9]/.test(this.mobilePhoneNumber)) {
      this.mobilePhoneNumberErrors = [
        "휴대폰번호를 “-” 제외하고 숫자만 입력하세요.",
      ];
      return this.mobilePhoneNumberErrors;
    }

    // 출처: https://thereclub.tistory.com/3 [강남부자]
    // 그리고 잘못된 PIPE(|) 삭제
    if (
      !/^((01[16789])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/.test(
        this.mobilePhoneNumber
      )
    ) {
      this.mobilePhoneNumberErrors = ["올바른 휴대폰번호를 입력해주세요."];
      return this.mobilePhoneNumberErrors;
    }
    return this.mobilePhoneNumberErrors;
  }

  private setSmsVerificationCountdown(number: number) {
    this.smsVerificationCountdown = number;
  }

  private setSmsVerificationIntervalId(intervalId?: NodeJS.Timeout) {
    if (this.smsVerificationIntervalId) {
      clearInterval(this.smsVerificationIntervalId);
    }
    this.smsVerificationIntervalId = intervalId;
  }

  private setSmsVerificationRequestId(requestId: string) {
    this.smsVerificationRequestId = requestId;
  }

  private setSmsVerification(verification: SmsVerification) {
    this.smsVerification = verification;
  }

  private setSmsVerificationVerifying(verifying: boolean) {
    this.smsVerificationVerifying = verifying;
  }

  private validateSmsVerification(): string[] {
    if (!this.mobilePhoneNumberErrors.length) {
    }
    this.smsVerificationErrors = [];
    if (this.smsVerification !== SmsVerification.VERIFIED) {
      this.smsVerificationErrors = ["휴대폰 본인 인증이 필요합니다."];
      return this.smsVerificationErrors;
    }
    return this.smsVerificationErrors;
  }

  private validateName(): string[] {
    this.nameErrors = [];
    this.name = this.name.trim();
    if (this.name.length < 1) {
      this.nameErrors.push("성명을 입력해주세요");
    }
    return this.nameErrors;
  }

  private validateEmail(): string[] {
    this.emailErrors = [];
    let domain = "";
    switch (this.emailDomain) {
      case EmailDomain.NAVER:
        domain = "naver.com";
        break;
      case EmailDomain.DAUM:
        domain = "daum.net";
        break;
      case EmailDomain.GMAIL:
        domain = "gmail.com";
        break;
      case EmailDomain.CUSTOM:
        domain = this.emailCustomDomain;
        break;
    }
    this.email = `${this.emailId}@${domain}`;

    if (this.email.length < 1 || !email.isValid(this.email)) {
      this.emailErrors.push("Korea@ngenebio.co 형태로 입력해주세요");
    }
    return this.emailErrors;
  }
}

export class AgreementItem {
  readonly title: string;
  readonly required: boolean;
  agree: boolean = false;

  constructor(name: string, required: boolean) {
    this.title = name;
    this.required = required;

    makeAutoObservable(this);
  }

  setAgree(agree: boolean) {
    this.agree = agree;
  }
}

export class Agreement {
  readonly request = new AgreementItem("유전자 검사 의뢰서", true);
  readonly consent = new AgreementItem("유전자 검사 동의서", true);
  readonly consentVoluntarily = {
    agree: false,
    setAgree: (agree: boolean) => {
      this.consentVoluntarily.agree = agree;
      this.updateConsentAgree();
    },
  };
  readonly consentAdditional = {
    agree: false,
    setAgree: (agree: boolean) => {
      this.consentAdditional.agree = agree;
      this.updateConsentAgree();
    },
  };
  readonly personal = new AgreementItem(
    "개인정보 수집 및 이용에 대한 동의",
    true
  );
  readonly sensitive = new AgreementItem(
    "민감정보 수집 및 이용에 대한 동의",
    true
  );
  readonly personalThirdParties = new AgreementItem(
    "개인정보 제3자 제공에 대한 동의",
    true
  );
  readonly sensitiveThirdParties = new AgreementItem(
    "민감정보 제3자 제공에 대한 동의",
    true
  );
  readonly entrustment = new AgreementItem("개인정보의 위탁에 관한 동의", true);
  readonly humanMaterials = new AgreementItem("인체유래물 연구 동의 ", false);
  readonly marketing = new AgreementItem("마케팅 정보 수신에 대한 동의", false);
  signature: string = "";
  private readonly agreementItems = [
    this.request,
    this.consent,
    this.personal,
    this.sensitive,
    this.personalThirdParties,
    this.sensitiveThirdParties,
    this.entrustment,
    this.humanMaterials,
    this.marketing,
  ];

  constructor() {
    makeAutoObservable(this);
  }

  isAllAgreed(): boolean {
    return !includes(
      this.agreementItems.map((x) => x.agree),
      false
    );
  }

  setAllAgreed(agree: boolean) {
    this.agreementItems.forEach((item) => item.setAgree(agree));
    this.consentVoluntarily.setAgree(agree);
    this.consentAdditional.setAgree(agree);
  }

  validate(): string[] {
    return this.agreementItems
      .filter((x) => !x.agree && x.required)
      .map(
        (x) =>
          `필수항목을 읽고 동의해주셔야 서비스 이용이 가능합니다.\n\n동의가 필요합니다. [${x.title}]`
      );
  }

  setSignature(signature: string) {
    this.signature = signature;
  }

  private updateConsentAgree() {
    this.consent.agree =
      this.consentVoluntarily.agree && this.consentAdditional.agree;
  }
}

export class SurveyQuestion {
  readonly question: string;
  answer: boolean | undefined;

  constructor(question: string) {
    this.question = question;

    makeAutoObservable(this);
  }

  setAnswer(answer: boolean) {
    this.answer = answer;
  }
}

const questions = [
  "복부에 가스가 차거나 더부룩한 느낌이 난다.",
  "위 기능이 예전만 못해 식후에 속이 불편하다. ",
  "소화가 잘 안되고 쉽게 체한다.",
  "쉽게 배탈이 나고 설사를 한다.",
  "비염, 아토피, 습진 등 알레르기 질환이 있다.",
  "감기에 잘 걸리는 편이다.",
  "장염에 잘 걸리는 편이다.",
  "일주일에 1~2회만 변을 본다.",
  "식사를 굶거나 대충 때우는 일이 많다.",
  "눈 충혈이 잘 되어 인공 눈물을 자주 사용한다.",
  "하루에 3회 이상 변을 보기도 한다.",
  "스마트폰을 오래보면 눈이 무겁고 뻐근하다.",
  "따로 다이어트를 하지 않았는데도 다리가 가늘어졌다. ",
  "술자리 다음날 숙취로 힘들다.",
  "다리 힘이 예전보다 약해진 것이 느껴진다. ",
  "고기를 먹으면 소화가 잘 안되어 섭취가 꺼려진다. ",
  "무엇을 하려고 했는지 잘 기억나지 않는 일이 많다.",
  "잘 다루던 기구의 조작이 어느샌가 서툴러졌다.",
  "예전에 비해 물건을 자주 잃어버리거나 찾기 힘들다.",
  "무릎이 아파 계단을 오르내리기 힘들다. ",
  "무거운 짐을 들고 자주 걸어다닌다. ",
  "화 나는 일이 많아 가슴이 답답하다.",
  "밤에 잠을 설치고 쉽게 잠들지 못한다.",
  "머리카락이 가늘거나 잘 빠진다.",
  "예전보다 화를 잘 억누르지 못한다.",
  "손톱이 잘 자라지 않고 쉽게 부러진다.",
  "갑자기 얼굴에 열이 오르며 붉어지고 땀이 난다.",
  "쉽게 울적해지거나 갑자기 신경질이 난다. ",
  "매사 무기력하고 의욕이 줄었다.",
  "스테미너 증진이 필요하다.",
];

export class State {
  page: Page = Page.Index;
  readonly environment: Environment =
    find(environments, (x) => x === process.env.REACT_APP_ENVIONMENT) ??
    Environment.Production;
  readonly personalInformation = new PersonalInformation();
  readonly agreement = new Agreement();

  height: string = "";
  weight: string = "";
  waist: string = "";

  readonly surveyQuestions = questions.map(
    (question) => new SurveyQuestion(question)
  );

  // 검체 번호
  sampleId = "";

  apiHostName: string =
    process.env.REACT_APP_API_HOST_NAME ?? "https://example.com";
  disableNetwork: boolean = false;
  private sending = false;

  constructor() {
    const params = parseParams();
    this.sampleId = params.sampleId ?? this.sampleId;

    if (
      this.environment === Environment.Development ||
      this.environment === Environment.Test
    ) {
      const hashConfig = parseHashConfig();
      this.page = hashConfig.page ?? this.page;
      this.disableNetwork = hashConfig.disableNetwork;
      this.apiHostName = hashConfig.apiHostName ?? this.apiHostName;
      this.personalInformation.smsVerificationMinimumWait =
        hashConfig.smsVerificationMinimumWait ??
        this.personalInformation.smsVerificationMinimumWait;
      this.personalInformation.smsVerificationMaxWait =
        hashConfig.smsVerificationMaxWait ??
        this.personalInformation.smsVerificationMaxWait;

      // 개발 중 검체 번호를 입력하지 않을 경우 기본값 추가
      if (params.sampleId === undefined) {
        this.sampleId = "XXX-XXXXX-XX";
      }
      if (hashConfig.data === 1) {
        this.personalInformation.setName("존 도");
        this.personalInformation.mobilePhoneNumber = "01012345678";
        this.personalInformation.email = "test@example.com";
        this.personalInformation.emailId = "test";
        this.personalInformation.emailDomain = EmailDomain.CUSTOM;
        this.personalInformation.emailCustomDomain = "@example.com";
        this.personalInformation.smsVerificationRequestId =
          "smsVerificationRequestId";
        this.personalInformation.smsVerificationSecret =
          "smsVerificationSecret";
        this.personalInformation.smsVerification = SmsVerification.VERIFIED;
        this.personalInformation.allowResetSmsVerification = true;
        const now = moment();
        this.personalInformation.setSampleYear(now.year().toString());
        this.personalInformation.setSampleMonth(now.month().toString());
        this.personalInformation.setSampleDay(now.day().toString());
        this.personalInformation.setSampleHour((now.hour() % 12).toString());
        this.personalInformation.setSampleMinute(now.minute().toString());
        this.personalInformation.birthday = moment().subtract(30, "years");
        this.personalInformation.setBirthYear(
          this.personalInformation.birthday.year().toString()
        );
        this.personalInformation.setBirthMonth(
          this.personalInformation.birthday.month().toString()
        );
        this.personalInformation.setBirthDay(
          this.personalInformation.birthday.day().toString()
        );

        const dummyAddress: AddressData = {
          zonecode: "zonecode",
          address: "address",
          addressEnglish: "addressEnglish",
          addressType: "R",
          userSelectedType: "R",
          userLanguageType: "K",
          roadAddress: "roadAddress",
          roadAddressEnglish: "roadAddressEnglish",
          jibunAddress: "jibunAddress",
          jibunAddressEnglish: "jibunAddressEnglish",
          autoRoadAddress: "autoRoadAddress",
          autoRoadAddressEnglish: "autoRoadAddressEnglish",
          autoJibunAddress: "autoJibunAddress",
          autoJibunAddressEnglish: "autoJibunAddressEnglish",
          buildingCode: "buildingCode",
          buildingName: "buildingName",
          apartment: "Y",
          sido: "sido",
          sigungu: "sigungu",
          sigunguCode: "sigunguCode",
          roadnameCode: "roadnameCode",
          bcode: "bcode",
          roadname: "roadname",
          bname: "bname",
          bname1: "bname1",
          bname2: "bname2",
          hname: "hname",
          query: "query",
          noSelected: "Y",
        };
        this.personalInformation.setAddress(dummyAddress);
        this.personalInformation.setAddressDetail("상세 주소");
        this.personalInformation.setSex(Sex.Female);
        this.personalInformation.setContractNumber("CT1234567890");
        this.personalInformation.setCounselor("철수");
        // 동의
        this.agreement.setAllAgreed(true);
        this.agreement.setSignature("signature");
        // 설문
        this.height = "175";
        this.weight = "80";
        this.waist = "100";
        this.surveyQuestions.forEach((q, i) => q.setAnswer(i % 2 === 0));
      }
    }

    makeAutoObservable(this);
  }

  go(page: Page) {
    this.page = page;
  }

  validateSurveyQuestion(): string | undefined {
    if (this.height.length < 1) {
      return "키를 입력해주세요.";
    }
    if (this.weight.length < 1) {
      return "체중을 입력해주세요.";
    }
    if (this.waist.length < 1) {
      return "허리둘레를 입력해주세요.";
    }

    return this.surveyQuestions
      .map((question, index) => ({ q: question, i: index }))
      .filter((x) => x.q.answer === undefined)
      .map(
        (x) =>
          `Q ${(x.i + 1).toString().padStart(2, "0")} ${
            x.q.question
          }\n질문에 답을 선택하여 주십시오.`
      )[0];
  }

  sendToServer() {
    if (this.sending) {
      alert("동의서 등록 중입니다.\n잠시 후 다시 시도하여 주십시오.");
      return;
    }

    this.sending = true;
    const data = {
      sampleId: state.sampleId,
      customCi: "TODO :: PASS 인증 정보",
      name: this.personalInformation.name,
      smsVerificationRequestId:
        state.personalInformation.smsVerificationRequestId,
      smsVerificationSecret: state.personalInformation.smsVerificationSecret,
      email: state.personalInformation.email,
      gender: state.personalInformation.sex === Sex.Male ? "M" : "F",
      address:
        (state.personalInformation.address?.address ?? "N/A") +
        " " +
        state.personalInformation.addressDetail,
      addressJson: JSON.stringify(state.personalInformation.address),
      postNum: state.personalInformation.address?.zonecode ?? "N/A",
      // TODO :: 인종코드 로 변경후 substr 삭제, 현재 DB 가 10칸이라서 입력이 불가능하다.
      peopleCode: state.personalInformation.race.substr(0, 10),
      productCode: state.personalInformation.product,
      surveyInfo: state.surveyQuestions
        .map((x) => (x.answer ? "Y" : "N"))
        .join("|"),
      contractNum: parseInt(
        state.personalInformation.contractNumber.replace(/^CT/i, ""),
        0
      ),
      consultant: state.personalInformation.counselor,
      collectDt: state.personalInformation.sampleDate.toISOString(true),
      birthday: state.personalInformation.birthday.format("YYYYMMDD"),
      height: state.height,
      weight: state.weight,
      waist: state.waist,
      agreement: {
        allYn: yn(state.agreement.isAllAgreed()),
        requestYn: yn(state.agreement.request.agree),
        consentYn: yn(state.agreement.consent.agree),
        personalYn: yn(state.agreement.personal.agree),
        sensitiveYn: yn(state.agreement.sensitive.agree),
        personalThirdPartiesYn: yn(state.agreement.personalThirdParties.agree),
        sensitiveThirdPartiesYn: yn(
          state.agreement.sensitiveThirdParties.agree
        ),
        entrustmentYn: yn(state.agreement.entrustment.agree),
        humanMaterialsYn: yn(state.agreement.humanMaterials.agree),
        marketingYn: yn(state.agreement.marketing.agree),
        signature: state.agreement.signature,
      },
    };
    request("/mng/hsu/customer", data)
      .then(function (json) {
        if (alertErrors(json)) {
          return;
        }

        alert("동의 신청이 완료되었습니다.");
        state.go(Page.Complete);
      })
      .catch(defaultExceptionHandler)
      .finally(() => {
        this.sending = false;
      });
  }

  setHeight(height: string) {
    this.height = numberOnly(height);
  }

  setWeight(weight: string) {
    this.weight = numberOnly(weight);
  }

  setWaist(waist: string) {
    this.waist = waist;
  }

  onEditPage() {
    return [
      Page.PersonalInformation,
      Page.PersonalInformationConfirm,
      Page.Agreement,
      Page.Survey,
    ].includes(this.page);
  }
}

const yn = (bool: boolean) => (bool ? "Y" : "N");

export const state = new State();

interface HashConfig {
  page: Page | undefined;
  data: number | undefined;
  apiHostName?: string;
  disableNetwork: boolean;
  smsVerificationMaxWait?: number;
  smsVerificationMinimumWait?: number;
}

function parseHashConfig(): HashConfig {
  const hash =
    window.location.hash[0] === "#"
      ? window.location.hash.substr(1)
      : window.location.hash;
  const parsed = queryString.parse(hash);
  return {
    page: find(pages, (page) => page === parseIntQuery(parsed, "page")),
    data: parseIntQuery(parsed, "data"),
    apiHostName: reduceParseQuery(parsed, "apiHostName"),
    disableNetwork: Object.keys(parsed).includes("disableNetwork"),
    smsVerificationMaxWait: parseIntQuery(parsed, "smsVerificationMaxWait"),
    smsVerificationMinimumWait: parseIntQuery(
      parsed,
      "smsVerificationMinimumWait"
    ),
  };
}

function parseIntQuery(
  parsed: ParsedUrlQuery,
  key: string
): number | undefined {
  const value = reduceParseQuery(parsed, key);
  if (value === undefined) {
    return undefined;
  }
  const int = parseInt(value, 10);
  if (Number.isNaN(int)) {
    return undefined;
  }
  return int;
}

function reduceParseQuery(
  parsed: ParsedUrlQuery,
  key: string
): string | undefined {
  const value = parsed[key];
  if (value === undefined) {
    return undefined;
  }
  if (typeof value === "string") {
    return value;
  }
  return value[0];
}

interface Params {
  sampleId: string | undefined;
}

function parseParams(): Params {
  const hash =
    window.location.search[0] === "?"
      ? window.location.search.substr(1)
      : window.location.search;
  const parsed = queryString.parse(hash);
  return {
    sampleId: reduceParseQuery(parsed, "id"),
  };
}

function request(url: string, body: any) {
  if (state.disableNetwork) {
    return new Promise<any>((resolve, reject) => {
      reject(new Error("네트워크 사용 불가"));
    });
  }

  //alert(state.apiHostName + url);

  return fetch(state.apiHostName + url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((response) => {
    if (!response.ok) {
      return response.text().then((text) => {
        console.error(response);
        throw new Error(text);
      });
    }
    return response.json();
  });
}

interface RequestResponse {
  errMsg?: string;
  errors?: { [property: string]: string[] };
}

function alertErrors(response: RequestResponse): boolean {
  if (response.errMsg) {
    alert(response.errMsg);
    return true;
  }
  if (response.errors && Object.keys(response.errors).length) {
    const messages = Object.values(response.errors)
      .flatMap((x) => x)
      .join("\n");
    alert(messages);
    return true;
  }
  return false;
}

function defaultExceptionHandler(e: any) {
  console.error(e);
  alert("통신 장애가 발생하였습니다. 잠시 후 다시 시도하여 주십시오.");
}
